import { format } from 'date-fns';

export const formatDate = {
  methods: {
    formatDate(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      }
    },
    formatDateISO(date) {
      if (date) {
        return format(new Date(date), 'yyyy-MM-dd');
      }
    },
  },
};

export const formatDateWithHour = {
  methods: {
    formatDateWithHour(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy HH:mm');
      }
    },
    formatDateWithHourConditionally(date) {
      if (date) {
        const withHours = date.split(' ')[1] ? true : false;

        if (withHours) {
          return format(new Date(date), 'dd.MM.yyyy HH:mm');
        } else {
          return format(new Date(date), 'dd.MM.yyyy');
        }
      }
    },
  },
};
